var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v(" My Investments ")]),(_vm.accountConnected)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-h6 mb-0"},[_vm._v(" Total token balance: "+_vm._s(_vm._f("toNumber")(_vm.total()))+" ")])]),_c('v-col',{staticClass:"d-none d-md-block text-right"},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)],1)],1):_vm._e(),(_vm.accountConnected)?_c('v-data-table',{staticClass:"mt-5 mb-4 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading investments...","no-data-text":"No investments found. Make sure you've selected the right account and network.","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'project', params: { slug: item.slug }}}},[_c('v-img',{staticClass:"my-4 rounded elevation-1",attrs:{"src":item.image,"width":"100px","height":"65px"}})],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'project', params: { slug: item.slug }}}},[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.title))])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hash text-decoration-none",attrs:{"target":"_blank","href":((_vm.$store.getters.explorerUrlFor(_vm.$store.getters.chainId)) + "/token/" + (item.address))}},[_vm._v(_vm._s(item.address))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm._f("toNumber")(item.balance))+" "+_vm._s(item.symbol))])]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","to":{ name: 'report', params: { slug: item.slug }}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiChartBox)+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.accountConnected)?_c('p',{staticClass:"text-center text-caption mb-0"},[_vm._v(" Please note that for projects which use the partnership flip financing model your token balance will not be the same as your investment amount. Before the flip occurres your balance will be higher than your investment amount and after the flip it will be lower. The relevant percentages can be found in the investment agreement. ")]):_vm._e(),(!_vm.accountConnected)?_c('v-alert',{attrs:{"type":"info","prominent":"","outlined":"","text":""}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Connect your Ethereum wallet to view your investments. ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }