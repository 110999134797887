<template>
  <div>
    <h1 class="mb-4">
      My Investments
    </h1>
    <v-row v-if="accountConnected">
      <v-col cols="12" md="6">
        <p class="text-h6 mb-0">
          Total token balance: {{ total() | toNumber }}
        </p>
      </v-col>
      <v-col class="d-none d-md-block text-right">
        <v-btn
          fab
          small
          @click="refresh"
        >
          <v-icon>
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      v-if="accountConnected"
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading investments..."
      no-data-text="No investments found. Make sure you've selected the right account and network."
      class="mt-5 mb-4 elevation-1"
      hide-default-header
      hide-default-footer
    >
      <template #[`item.image`]="{item}">
        <router-link class="text-decoration-none" :to="{ name: 'project', params: { slug: item.slug }}">
          <v-img
            :src="item.image"
            width="100px"
            height="65px"
            class="my-4 rounded elevation-1"
          ></v-img>
        </router-link>
      </template>

      <template #[`item.title`]="{item}">
        <router-link class="text-decoration-none" :to="{ name: 'project', params: { slug: item.slug }}">
          <span class="text-body-1">{{ item.title }}</span>
        </router-link>
      </template>

      <template #[`item.address`]="{item}">
        <a class="hash text-decoration-none" target="_blank" :href="`${$store.getters.explorerUrlFor($store.getters.chainId)}/token/${item.address}`">{{ item.address }}</a>
      </template>

      <template #[`item.balance`]="{item}">
        <span class="text-body-1">{{ item.balance | toNumber }} {{ item.symbol }}</span>
      </template>

      <template #[`item.report`]="{item}">
        <v-btn
          fab
          small
          :to="{ name: 'report', params: { slug: item.slug }}"
        >
          <v-icon>
            {{ icons.mdiChartBox }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <p
      v-if="accountConnected"
      class="text-center text-caption mb-0"
    >
      Please note that for projects which use the partnership flip financing model your token balance will not be the same as your investment amount.
      Before the flip occurres your balance will be higher than your investment amount and after the flip it will be lower.
      The relevant percentages can be found in the investment agreement.
    </p>

    <v-alert
      v-if="!accountConnected"
      type="info"
      prominent
      outlined
      text
    >
      <p class="mb-0">
        Connect your Ethereum wallet to view your investments.
      </p>
    </v-alert>
  </div>
</template>

<script>
import { mdiRefresh, mdiChartBox } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiRefresh,
        mdiChartBox,
      },
    }
  },
  data() {
    return {
      headers: [
        { text: 'Image', value: 'image' },
        { text: 'Title', value: 'title' },
        { text: 'Address', value: 'address' },
        { text: 'Balance', value: 'balance' },
        { text: 'Report', value: 'report' },
      ],
      loading: false,
      accountConnected: false,
    }
  },
  computed: {
    items() {
      return this.$store.getters.investmentsItems
    },
    loaded() {
      return this.$store.getters.investmentsLoaded
    },
    projects() {
      return this.$store.getters.projectsChain
    },
  },
  watch: {
    '$store.state.wallet': function(wallet) {
      this.refresh()
      if (wallet) {
        this.accountConnected = true
      } else {
        this.accountConnected = false
      }
    },
    '$store.state.chainId': function(chainId) {
      this.refresh()
    },
  },
  mounted() {
    if (this.$store.getters.wallet) {
      this.accountConnected = true
      this.load()
    }
  },
  methods: {
    load() {
      if (!this.loaded && !this.loading && this.projects.length > 0) {
        this.loading = true
        setTimeout(() => {
          this.projects.forEach((project) => {
            this.$web3.eth.getCode(project.address).then((code) => {
              if (code !== '0x') {
                var instance = new this.$web3.eth.Contract(this.$PartnershipFlipABI, project.address)
                instance.methods.balanceOf(this.$store.getters.wallet).call().then((balance) => {
                  if (balance > 0) {
                    var item = {
                      image: project.image,
                      title: project.title,
                      slug: project.slug,
                      address: project.address,
                      symbol: project.symbol,
                      balance: Number(this.$fromWei(balance)),
                    }
                    this.$store.commit('investmentsAddItem', item)
                  }
                  this.$store.commit('investmentsLoaded')
                  this.loading = false
                })
              }
            })
          })
        }, 10)
      }
    },
    total() {
      var total = 0
      this.items.forEach((item) => {
        total += item.balance
      })
      return total
    },
    refresh() {
      this.$store.commit('investmentsClear')
      this.load()
    },
  },
}
</script>

<style scoped>
  .hash {
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
